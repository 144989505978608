import * as yup from "yup";

const validationMessages = {
    required: "This field is required",
    logoRequired: "Logo is required",
    email: "Invalid email address",
    website: "Invalid website URL",
    phone: "Invalid phone number",
};

export const businessListingSchema = (isPremiumListing: boolean) =>
    yup
        .object({
            companyName: yup.string().required(validationMessages.required),
            category: yup.number(),
            description: yup
                .string()
                .required(validationMessages.required)
                .matches(/^[^\p{Extended_Pictographic}]+$/u, "Description cannot contain emojis"),
            website: yup
                .string()
                .url(validationMessages.website)
                .required(validationMessages.required),
            email: isPremiumListing
                ? yup.string().email().required(validationMessages.required)
                : yup.string().notRequired(),
            phone: isPremiumListing
                ? yup
                      .string()
                      .matches(/^\d+[-\d\s]*\d+$/, {
                          message: "Invalid phone number format",
                          excludeEmptyString: false,
                      })
                      .required(validationMessages.required)
                : yup.string().notRequired(),
            thumbnail_image: isPremiumListing
                ? yup.object({
                      base64: yup.string().required(validationMessages.logoRequired),
                  })
                : yup.object({
                      base64: yup.string().notRequired(),
                  }),
        })
        .required();
