import React, { ReactNode, createContext, useContext } from "react";
import {
    BudgetFormDataType,
    BudgetItemCategoryType,
    BudgetItemFormModes,
    BudgetItemType,
    BudgetType,
    ExtendBudgetItemType,
} from "app/types/budget/budget.types";
import { useGetBudgetFormData } from "app/utils/api/queries/budget.query";
import { IUser } from "app/store/types/user.types";
import ProTooltip from "app/components/elements/wrappers/pro_tooltip";
import { PerCustomOutlined, PerEventOutlined, PerPaticipantOutlined } from "assets";
import { Controller, FieldError, UseFormReturn } from "react-hook-form";
import { Input, InputNumber, Select } from "antd";
import FormItemWrapper from "app/components/elements/form/form_item_wrapper";
import { ChevronDownOutlined } from "assets/icons/icons";
import { HELP_LINKS } from "../constants";

interface ItemFieldsContextProps {
    formContext: UseFormReturn<ExtendBudgetItemType, any, undefined>;
    formData: BudgetFormDataType | undefined;
    mode: BudgetItemFormModes;
    item:
        | Partial<
              BudgetItemType & {
                  quantity_override: number | null;
              }
          >
        | undefined;
    type: "cost" | "revenue";
    budget: BudgetType | undefined;
    user: Partial<IUser>;
    canAccessBB: boolean;
    children?: ReactNode;
}

const ItemFieldsContext = createContext<ItemFieldsContextProps | undefined>(undefined);

const useItemFieldsContext = () => {
    const context = useContext(ItemFieldsContext);
    if (!context) {
        throw new Error("useItemFieldsContext must be used within an ItemFieldsProvider");
    }
    return context;
};

const ItemFieldsProvider: React.FC<ItemFieldsContextProps> = ({ children, ...props }) => (
    <ItemFieldsContext.Provider value={props}>{children}</ItemFieldsContext.Provider>
);

const SearchField = () => {
    const {
        formContext: {
            watch,
            control,
            setValue,
            formState: { errors },
        },
        type,
    } = useItemFieldsContext();

    const { data: formData } = useGetBudgetFormData();
    const allSearchableIetms = formData?.budget_item_categories.filter((o) => o.name !== "Other");
    const searchableItemsByType = allSearchableIetms?.filter(
        (c) => c.type === watch("virtual_type")
    );
    const groupedSearchableItems: { label: string; options: BudgetItemCategoryType[] }[] =
        searchableItemsByType
            ? Object.values(
                  searchableItemsByType.reduce((acc, o) => {
                      const { group } = o;
                      if (!acc[group]) {
                          acc[group] = {
                              label: group.replace(/\b\w/g, (match) => match.toUpperCase()),
                              options: [],
                          };
                      }
                      acc[group].options.push({ label: o.name, value: JSON.stringify(o) });
                      return acc;
                  }, {})
              )
            : [];

    return (
        <FormItemWrapper label={`Pick a ${type} item:`} error={errors.virtual_search_key}>
            <Controller
                name="virtual_search_key"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                    <Select
                        suffixIcon={<ChevronDownOutlined />}
                        className="add-edit-budget__search"
                        data-hj-allow
                        status={`${errors.virtual_search_key ? "error" : ""}`}
                        style={{ width: "100%" }}
                        showSearch
                        options={groupedSearchableItems}
                        onChange={(value) => {
                            onChange(JSON.parse(value as string));

                            const searchItem = JSON.parse(value);
                            setValue("category", searchItem);
                            setValue("virtual_group", searchItem.group);
                            setValue("virtual_type", searchItem.type);
                        }}
                        value={
                            watch("virtual_search_key")
                                ? JSON.stringify(watch("virtual_search_key"))
                                : undefined
                        }
                        {...rest}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const VirtualGroupField = () => {
    const {
        formContext: {
            watch,
            setValue,
            control,
            clearErrors,
            formState: { errors },
        },
        mode,
        item,
        formData,
    } = useItemFieldsContext();

    const renderGroupLabel = () => {
        if (mode.isAddMode) {
            if (watch("virtual_type") === "cost") {
                return "Cost group";
            }

            if (watch("virtual_type") === "revenue") {
                return "Revenue group";
            }
        }

        if (mode.isEditMode) {
            if (item?.category?.type === "cost") {
                return "Cost group";
            }

            if (item?.category?.type === "revenue") {
                return "Revenue group";
            }
        }

        return "Group";
    };

    const virtualGroups = new Set(
        formData?.budget_item_categories
            .filter((c) => c.type === watch("virtual_type"))
            .map((c) => c.group)
    );

    const virtualGroupOptions = Array.from(virtualGroups).map((g) => ({
        label: g,
        value: g,
    }));

    const cost_revenue = watch("virtual_type") === "cost" ? "cost" : "revenue";

    return (
        <FormItemWrapper
            label={renderGroupLabel()}
            required
            tooltipText={
                <span>
                    The group this {cost_revenue} item belongs to.{" "}
                    <a
                        className="link-inline"
                        href={
                            cost_revenue === "cost"
                                ? HELP_LINKS.budgetItemEditor.costGroup
                                : HELP_LINKS.budgetItemEditor.revenueGroup
                        }
                        target="learnMoreFrame"
                    >
                        Learn more
                    </a>
                </span>
            }
            error={errors.virtual_group}
        >
            <Controller
                name="virtual_group"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                    <Select
                        suffixIcon={<ChevronDownOutlined />}
                        data-hj-allow
                        status={`${errors.virtual_group ? "error" : ""}`}
                        style={{ width: "100%" }}
                        options={virtualGroupOptions}
                        showSearch
                        onChange={(value: string) => {
                            onChange(value);
                            clearErrors("virtual_group");

                            setValue("category", null);

                            if (mode.isSelectMode && !watch("virtual_search_key")) {
                                setValue(
                                    "category",
                                    formData?.budget_item_categories?.find(
                                        (item) => item.group === value && item.name === "Other"
                                    ) || null
                                );
                            }
                        }}
                        {...rest}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const ItemTypeField = () => {
    const {
        formContext: {
            formState: { errors },
            watch,
            control,
        },
        mode,
        formData,
    } = useItemFieldsContext();

    const cost_revenue = watch("virtual_type") === "cost" ? "cost" : "revenue";

    const options = formData?.budget_item_categories
        .filter((c) => c.group === watch("virtual_group"))
        .map((o) => ({
            label: o.name,
            value: JSON.stringify(o),
        }));

    return (
        <FormItemWrapper
            label="Item type"
            required
            tooltipText={
                <span>
                    The type of {cost_revenue} represented by this item.{" "}
                    <a
                        className="link-inline"
                        href={HELP_LINKS.budgetItemEditor.itemType}
                        target="learnMoreFrame"
                    >
                        Learn more
                    </a>
                </span>
            }
            error={errors.category as FieldError}
        >
            <Controller
                name="category"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                    <Select
                        suffixIcon={<ChevronDownOutlined />}
                        data-hj-allow
                        status={`${errors.category ? "error" : ""}`}
                        style={{ width: "100%" }}
                        options={options}
                        disabled={mode.isSelectMode && !watch("virtual_group")}
                        defaultValue={
                            mode.isSelectMode && !watch("virtual_group") ? "Other" : undefined
                        }
                        showSearch
                        onChange={(value) => {
                            onChange(JSON.parse(value as string));
                        }}
                        value={watch("category") ? JSON.stringify(watch("category")) : undefined}
                        {...rest}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const ItemNameField = () => {
    const {
        formContext: {
            formState: { errors },
            control,
        },
    } = useItemFieldsContext();

    return (
        <FormItemWrapper label="Item name" error={errors.name_override}>
            <Controller
                name="name_override"
                control={control}
                render={({ field }) => (
                    <Input
                        status={errors?.name_override ? "error" : ""}
                        style={{ width: "100%" }}
                        maxLength={48}
                        {...field}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const BudgetedPriceField = () => {
    const {
        formContext: {
            control,
            formState: { errors },
        },
        user,
    } = useItemFieldsContext();

    return (
        <FormItemWrapper
            label="Budgeted price"
            required
            tooltipText={
                <span>
                    The amount that has been budgeted for this item.{" "}
                    <a
                        className="link-inline"
                        href={HELP_LINKS.budgetItemEditor.budgetedPrice}
                        target="learnMoreFrame"
                    >
                        Learn more
                    </a>
                </span>
            }
        >
            <Controller
                name="price"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                    <InputNumber
                        controls={false}
                        status={errors?.price ? "error" : ""}
                        min={0}
                        prefix={user?.location?.ccy_symbol}
                        style={{ width: "100%" }}
                        onChange={(valNum) => {
                            if (valNum === null) return;

                            onChange(valNum);
                        }}
                        {...rest}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const ActualPriceField = () => {
    const {
        formContext: {
            control,
            watch,
            formState: { errors },
        },
        user,
        canAccessBB,
    } = useItemFieldsContext();

    const paid_earned = watch("virtual_type") === "cost" ? "paid for" : "earned on";

    return (
        <ProTooltip custom_cta={null} condition={canAccessBB}>
            <div>
                <FormItemWrapper
                    label="Actual price"
                    tooltipText={
                        <span>
                            The actual amount {paid_earned} for this item.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.budgetItemEditor.actualPrice}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    error={errors.actual_price}
                >
                    <Controller
                        name="actual_price"
                        control={control}
                        render={({ field }) => (
                            <InputNumber
                                controls={false}
                                status={errors?.actual_price ? "error" : ""}
                                min={0.01}
                                prefix={user?.location?.ccy_symbol}
                                style={{ width: "100%" }}
                                disabled={!canAccessBB}
                                {...field}
                            />
                        )}
                    />
                </FormItemWrapper>
            </div>
        </ProTooltip>
    );
};

const QuantityTypeField = () => {
    const {
        formData,
        formContext: {
            control,
            formState: { errors },
        },
    } = useItemFieldsContext();

    const quantityTypeOptions = Object.keys(formData?.quantity_types || {}).map((key) => ({
        key,
        label: formData?.quantity_types[key],
        value: key,
    }));

    const qty_type_icons: {
        [key: string]: JSX.Element;
    } = {
        participant: <PerPaticipantOutlined />,
        event: <PerEventOutlined />,
        custom: <PerCustomOutlined />,
    };

    return (
        <FormItemWrapper
            required
            label="Quantity type"
            error={errors.quantity_type}
            tooltipText={
                <span>
                    The quantity the price for this item applies to. Choose whether the price should
                    be applied to all participants in an event (per participant), whether it's a
                    fixed price for an entire event (=per event), or whether you would like to
                    specify a custom quantity for this item.{" "}
                    <a
                        className="link-inline"
                        href={HELP_LINKS.budgetItemEditor.quantityType}
                        target="learnMoreFrame"
                    >
                        Learn more
                    </a>
                </span>
            }
        >
            <Controller
                name="quantity_type"
                control={control}
                render={({ field }) => (
                    <Select
                        suffixIcon={<ChevronDownOutlined />}
                        data-hj-allow
                        status={`${errors.quantity_type ? "error" : ""}`}
                        style={{ width: "100%" }}
                        className="add-edit-budget__quantity-type-field"
                        popupClassName="add-edit-budget__quantity-type-popup"
                        rootClassName="add-edit-budget__quantity-type-field"
                        options={quantityTypeOptions.map((o) => ({
                            label: (
                                <>
                                    {qty_type_icons[o.value]}
                                    {o.label}
                                </>
                            ),
                            value: o.value,
                        }))}
                        {...field}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const QuantityOverrideField = () => {
    const {
        formContext: {
            formState: { errors },
            control,
        },
    } = useItemFieldsContext();

    return (
        <FormItemWrapper
            label="Quantity override"
            tooltipText="Set the quantity the price for this item should apply to."
            error={errors?.quantity_override}
            required
        >
            <Controller
                name="quantity_override"
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                    <InputNumber
                        controls={false}
                        status={errors?.quantity_override ? "error" : ""}
                        style={{ width: "100%" }}
                        onChange={(valNum) => {
                            if (valNum === null) return;

                            onChange(valNum);
                        }}
                        onKeyPress={(event) => {
                            // Allow only integer values
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        {...rest}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const EventField = () => {
    const {
        budget,
        formContext: {
            formState: { errors },
            control,
        },
    } = useItemFieldsContext();

    const options = budget
        ? [
              ...budget.events.map((e) => ({
                  label: e.name,
                  value: e.pk,
              })),
              {
                  label: "All events",
                  value: null,
              },
          ]
        : undefined;

    return (
        <FormItemWrapper
            label="Event"
            tooltipText={
                <span>
                    The event this price applies to. If you are using per-participant pricing, the
                    price will apply only to participants in that event. To apply the
                    per-participant price to all participants across all events, set this to "All
                    events".{" "}
                    <a
                        className="link-inline"
                        href={HELP_LINKS.budgetItemEditor.event}
                        target="learnMoreFrame"
                    >
                        Learn more
                    </a>
                </span>
            }
        >
            <Controller
                name="event"
                control={control}
                render={({ field }) => (
                    <Select
                        suffixIcon={<ChevronDownOutlined />}
                        data-hj-allow
                        status={`${errors.event ? "error" : ""}`}
                        style={{ width: "100%" }}
                        options={options}
                        {...field}
                    />
                )}
            />
        </FormItemWrapper>
    );
};

const ItemFields = {
    EventField,
    QuantityTypeField,
    QuantityOverrideField,
    ActualPriceField,
    BudgetedPriceField,
    ItemNameField,
    ItemTypeField,
    VirtualGroupField,
    SearchField,
};

export { ItemFields, ItemFieldsProvider };
