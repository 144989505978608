import { object, string, number, array } from "yup";

const validationMessages = {
    required: "This field is required",
};

export const budgetEventValidationSchema = object().shape({
    nodes: array()
        .of(
            object({
                pk: number().optional(),
                date: string().required(validationMessages.required),
                entry_fee: number().required(validationMessages.required),
                participants_curr: number().required(validationMessages.required),
                participants_proj: number()
                    .required(validationMessages.required)
                    .min(0, "Proj. Participants cannot be a negative number"),
            })
        )
        .required()
        .test(
            "sum-of-participants",
            "At least one proj. participants field has to be greater than zero.",
            (nodes) => {
                if (!nodes) return false;
                const sum = nodes.reduce((acc, node) => acc + (node.participants_proj || 0), 0);
                return sum > 0;
            }
        ),
});
